import { useState, useEffect } from 'react'
import { useAPI, useStore } from 'global'
import { toHash } from 'utils'
import Screen from './screen'

export default function SecuredProducts() {
  const api = useAPI()
  const { store } = useStore()
  const storeID = store?.id
  const [securedProducts, setSecuredProducts] = useState()
  const [products, setProducts] = useState()
  const [pagination, setPagination] = useState()

  useEffect(() => {
    if (!storeID) return
    api.getSecuredProducts(storeID, { on_port: true }).then(response => {
      const ports = toHash(response?.ports)
      setSecuredProducts(response?.securedProducts?.map(sp => ({ port: ports[sp.portId], ...sp })))
      setProducts(toHash(response?.products))
      setPagination(response?.meta)
    }).catch(() => console.warn('Unable to fetch the list of secured products.'))
  }, [storeID])

  return <Screen securedProducts={securedProducts} products={products} pagination={pagination} />
}
