import { useTranslation } from 'react-i18next'
import { useBLE } from 'utils'
import { Button, LoadingSpinner } from 'components'
import keycard from 'assets/images/keycard.svg'
import './styles.scss'

export const State = {
  locked: 'locked',
  loading: 'loading',
  unlocked: 'unlocked',
  rebooting: 'rebooting',
}

export default function BLEUnlockScreen(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const {
    deviceSerialNumber: serialNumber,
    isDeviceConnected: connected,
  } = useBLE()
  const {
    position,
    onUnlock,
    onDisconnect,
    state,
  } = props

  let connectionStatus = <p className='connection-status'>{t(connected ? 'ble.connected' : 'ble.scanning')}</p>
  if (connected && serialNumber) connectionStatus = (
    <div className='main-content col'>
      <h2>{position?.name || t('ble.unassignedLock')}</h2>
      <p className='subtitle'>{serialNumber}</p>
    </div>
  )

  return (
    <div className='ble-unlock-comp col'>
      <img src={keycard} />
      <div className='content-container col'>
        {state != State.rebooting && connectionStatus}
        {(state == State.loading || state == State.rebooting) && <LoadingSpinner />}
        {state == State.unlocked && <p>{t('ble.unlock.success')}</p>}
        {state == State.rebooting && <p>{t('ble.unlock.rebooting')}</p>}
      </div>
      {connected && state == State.locked &&
        <div className='button-row row'>
          {serialNumber && <Button onClick={onUnlock}>{t('ble.unlock.unlock')}</Button>}
          <Button outline onClick={onDisconnect}>{t('ble.disconnect')}</Button>
        </div>
      }
    </div>
  )
}
