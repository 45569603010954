import { State as PuckState } from 'protoModels/mti/store/puck/state_pb'
import { State as LockState } from 'protoModels/mti/store/lock/state_pb'
import { State as AlarmModuleState } from 'protoModels/mti/store/alarm_module/state_pb'
import { State as CXFlexState } from 'protoModels/mti/store/core_iii/state_pb'
import { State as SecurePlugState } from 'protoModels/mti/store/secure_plug/state_pb'
import { State as RamState } from 'protoModels/mti/store/ram/state_pb'
import { State as FM20State } from 'protoModels/mti/store/fm_20/state_pb'
import { State as ProximityPuckState } from 'protoModels/mti/store/proximity_puck/state_pb'
import { State as NXDIPuckState } from 'protoModels/mti/store/nxdi/state_pb'
import { HealthState } from 'protoModels/mti/health_state_pb'

export default function parse(topic, message) {
  return parserFor(topic).deserializeBinary(message)
}

function parserFor(topic) {
  switch (topic) {
  case 'alarm_module/state': return AlarmModuleState
  case 'core_iii/state': return CXFlexState
  case 'fm_20/state': return FM20State
  case 'lock/state': return LockState
  case 'nxdi/state': return NXDIPuckState
  case 'proximity_puck/state': return ProximityPuckState
  case 'puck/state': return PuckState
  case 'ram/state': return RamState
  case 'secure_plug/state': return SecurePlugState
  case 'sd/health':
  case 'alarm_module/health':
  case 'base/health':
  case 'base_v2/health':
  case 'core_iii/health':
  case 'fm_20/health':
  case 'lock/health':
  case 'nxdi/health':
  case 'powerflex/health':
  case 'proximity_puck/health':
  case 'puck/health':
  case 'puck_v2/health':
  case 'ram/health':
  case 'secure_plug/health':
    return HealthState

  default: return { deserializeBinary: () => null }
  }
}
