import { Router } from '@MTIConnect/sw-react-common'
import { history, useStore, useMobileAPI } from 'global'
import { KeyType } from 'enums'
import Button from 'components/button'

const { Link } = Router

function TabLink(props) {
  return (
    <Link to={props.to} replace>
      <div id="tab-link-comp">
        <div id="content">
          {props.children}
        </div>
        <div id="highlight" />
      </div>
    </Link>
  )
}

export default function StoreTabbar() {
  const { store } = useStore()
  const { isMobile } = useMobileAPI()

  return (
    <div id="tabbar-comp">
      <div id="breadcrumb-container">
        <Button flat icon="chevron_left" onClick={()=>history.back()} />
        <TabLink to="details">{store?.name}</TabLink>
      </div>
      <div id="action-container">
        <TabLink to="positions"><i className="material-icons">drag_indicator</i></TabLink>
        <TabLink to="zones"><i className="material-icons">dashboard</i></TabLink>
        <TabLink to="devices"><i className="material-icons">hub</i></TabLink>
        <TabLink to="keys"><i className="material-icons">key</i></TabLink>
        <TabLink to="securedProducts"><i className="material-icons">view_in_ar</i></TabLink>
        <TabLink to="reports"><i className="material-icons">assignment</i></TabLink>
        {isMobile && store?.allowedKeyTypes?.includes(KeyType.ble) &&
          <TabLink to="ble-lock-manager"><i className="material-icons">bluetooth</i></TabLink>}
      </div>
    </div>
  )
}
