import { useState } from 'react'
import { Router } from '@MTIConnect/sw-react-common'
import { ToastContainer, Zoom } from 'react-toastify'
import { Ready } from 'global/i18n'
import history from 'global/history'
import { APIProvider } from 'global/api'
import { SessionProvider } from 'contexts/session'
import { MobileAPIProvider } from 'global/mobileAPI'
import { BLEProvider, wrap } from 'utils'
import { MeProvider } from 'contexts/me'
import { OrganizationProvider } from 'contexts/organization'
import { LogProvider } from 'components/logProvider'
import 'react-toastify/dist/ReactToastify.css'
import 'global/styles.scss'

import AppLayout from 'layouts/app'
import AuthenticatedLayout from 'layouts/authenticated'
import Login from 'pages/login'
import AuthCodeExchange from 'pages/authCodeExchange'
import PasswordReset from 'pages/passwordReset'
import Organizations from 'pages/organizations'
import OrganizationDetails from 'pages/organizationDetails'
import Regions from 'pages/regions'
import Region from 'pages/region'
import Stores from 'pages/stores'
import Store from 'layouts/store'
import Users from 'pages/users/index'
import IOTDashboard from 'pages/iotDashboard/index'

const { Switch, Route } = Router

function App() {
  // This stalls rendering the rest of the app until we've finished detecting the user's language
  // and loading the translation file.
  // i18next also supports React's experimental Suspense feature, if we want to try using that.
  const [isLocaleReady, setIsLocaleReady] = useState(false)
  Ready.then(() => setIsLocaleReady(true))
  if (!isLocaleReady) return <></>

  const routes = (
    <Switch history={history}>
      <Route path="login/:teamKey" element={Login} />
      <Route path='auth/callback' element={AuthCodeExchange} />
      <Route path="passwords/edit" element={PasswordReset} />
      <Route isDefault element={AuthenticatedLayout}>
        <Route isDefault path="stores" element={Stores} />
        <Route path="stores/:storeID" element={Store} />
        <Route path="organizations" element={Organizations} />
        <Route path="organization" element={OrganizationDetails} />
        <Route path="regions" element={Regions} />
        <Route path="regions/:regionID" element={Region} />
        <Route path="users/:userID" element={Users} />
        <Route path="iot-dashboard" element={IOTDashboard} />
      </Route>
    </Switch>
  )

  return (
    <>
      {wrap(
        SessionProvider,
        MobileAPIProvider,
        LogProvider,
        BLEProvider,
        // The OrganizationProvider is above the APIProvider, because AppProvider users must provide an org id on all requests
        OrganizationProvider,
        APIProvider,
        MeProvider,
        AppLayout,
        routes,
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
      />
    </>
  )
}

export default App
