import { createContext, useContext, useEffect, useState } from 'react'
import { useAPI } from 'global'
import { toHash } from 'utils'

const Context = createContext()

export function ManufacturersProvider(props) {
  const api = useAPI()
  const [manufacturers, setManufacturers] = useState({})

  useEffect(() => {
    api.getManufacturers().then(response => {
      // TODO: sort by name here?
      setManufacturers(toHash(response?.manufacturers || []))
    }).catch(() => console.warn('unable to get the manufacturers list'))
  }, [])

  return (
    <Context.Provider value={{manufacturers}}>
      {props.children}
    </Context.Provider>
  )
}

export default function useManufacturers() {
  return useContext(Context)
}
