import { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from 'components'
import './styles.scss'

// Note: When using this component with static data/any data,
// ensure each object in the data array has a 'name' property.

function SearchableSelect(props, ref) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { onSearch, onValueChange, onTextChange, staticData, defaultValue, defaultText, label, required } = props

  const listView = useRef()
  const textField = useRef()
  const currentPage = useRef(0)
  const totalPages = useRef(0)

  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState(defaultValue)

  const loading = useRef(false)

  const search = (text, page) => {
    if (loading.current) return

    if (staticData) {
      // For static data, just filter the results.
      const filteredItems = staticData.filter(item => {
        const value = typeof item === 'string' ? item : item?.name
        return value?.toLowerCase().includes(text?.toLowerCase())
      })
      setItems(filteredItems)
    } else {
      loading.current = true
      onSearch(text, page)
    }
  }

  const timerRef = useRef()
  const searchWithDelay = () => {
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => search(textField.current?.value, 1), 250)
  }

  // Setup scroll listener
  useEffect(() => {
    // If we scroll to the bottom and there are more pages, query the next page
    const handleScroll = () => {
      const { scrollTop, scrollHeight, offsetHeight } = listView.current || { }
      if (scrollTop + offsetHeight >= scrollHeight && currentPage.current < totalPages.current) {
        search(textField.current?.value, currentPage.current + 1)
      }
    }

    listView.current?.addEventListener('scroll', handleScroll)
    return () => listView.current?.removeEventListener('scroll', handleScroll)
  }, [])

  // Sets the items and total pages.
  useImperativeHandle(ref, () => ({
    get value() { return selectedItem },
    set value(v) { select(v) },
    get text() { return textField.current?.value },

    addData: (newData, meta) => {
      if (staticData) return

      loading.current = false
      totalPages.current = Math.ceil(meta?.total / (meta?.perPage || meta?.per_page)) || 0
      currentPage.current = meta?.page || meta?.currentPage || meta?.current_page || currentPage.current

      if (currentPage.current == 1) setItems(newData || [])
      else setItems(prevData => [...(prevData || []), ...(newData || [])])
    },
  }))

  const select = item => {
    setSelectedItem(item)
    textField.current.value = typeof item === 'string' ? item : item?.name || ''
    onValueChange?.(item)
  }

  const onKeyPress = event => {
    if (event.key === 'Backspace' && selectedItem) {
      select(null)
      searchWithDelay()
    }
  }

  const focusedTimer = useRef(false)
  const onFocus = () => {
    clearTimeout(focusedTimer.current)
    if (!selectedItem) searchWithDelay()
  }

  // Note: manually setting the value of a text field does not trigger this callback
  const onChange = () => {
    searchWithDelay()
    onTextChange?.(textField.current.value)
  }

  // onBlur triggers before the click event when selecting an option, so add a timeout to allow the
  // click event to finish firing before clearing the list.
  const onBlur = () => {
    focusedTimer.current = setTimeout(() => {
      currentPage.current = 0
      setItems(null)
    }, 250)
  }

  return (
    <div className='searchable-select-comp col'>
      <TextField
        ref={textField}
        defaultValue={typeof selectedItem === 'string' ? selectedItem : selectedItem?.name || defaultText}
        onChange={onChange}
        onKeyDown={onKeyPress}
        label={label || t('global.search')}
        onFocus={onFocus}
        onBlur={onBlur}
        required={required}
        autoComplete='off'
      />
      <ul ref={listView} className={(selectedItem || !items?.length) ? 'empty' : 'open'}>
        {!selectedItem && items?.map((item, index) => (
          <li
            key={typeof item === 'string' ? item : item?.id || item?.name || index}
            onClick={()=>select(item)}>
            {typeof item === 'string' ? item : item?.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default forwardRef(SearchableSelect)
