// source: mti/health_state.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var mti_metadata_pb = require('../mti/metadata_pb.js');
goog.object.extend(proto, mti_metadata_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.mti.FixtureHealth', null, global);
goog.exportSymbol('proto.mti.HealthState', null, global);
goog.exportSymbol('proto.mti.HealthState.RuleOneofCase', null, global);
goog.exportSymbol('proto.mti.HealthStatus', null, global);
goog.exportSymbol('proto.mti.PositionHealth', null, global);
goog.exportSymbol('proto.mti.StoreHealth', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mti.StoreHealth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mti.StoreHealth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mti.StoreHealth.displayName = 'proto.mti.StoreHealth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mti.FixtureHealth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mti.FixtureHealth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mti.FixtureHealth.displayName = 'proto.mti.FixtureHealth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mti.PositionHealth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mti.PositionHealth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mti.PositionHealth.displayName = 'proto.mti.PositionHealth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mti.HealthState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mti.HealthState.oneofGroups_);
};
goog.inherits(proto.mti.HealthState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mti.HealthState.displayName = 'proto.mti.HealthState';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mti.StoreHealth.prototype.toObject = function(opt_includeInstance) {
  return proto.mti.StoreHealth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mti.StoreHealth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.StoreHealth.toObject = function(includeInstance, msg) {
  var f, obj = {
    storeId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mti.StoreHealth}
 */
proto.mti.StoreHealth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mti.StoreHealth;
  return proto.mti.StoreHealth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mti.StoreHealth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mti.StoreHealth}
 */
proto.mti.StoreHealth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStoreId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mti.StoreHealth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mti.StoreHealth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mti.StoreHealth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.StoreHealth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoreId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 store_id = 1;
 * @return {number}
 */
proto.mti.StoreHealth.prototype.getStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.StoreHealth} returns this
 */
proto.mti.StoreHealth.prototype.setStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mti.FixtureHealth.prototype.toObject = function(opt_includeInstance) {
  return proto.mti.FixtureHealth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mti.FixtureHealth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.FixtureHealth.toObject = function(includeInstance, msg) {
  var f, obj = {
    storeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fixtureId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mti.FixtureHealth}
 */
proto.mti.FixtureHealth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mti.FixtureHealth;
  return proto.mti.FixtureHealth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mti.FixtureHealth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mti.FixtureHealth}
 */
proto.mti.FixtureHealth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStoreId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFixtureId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mti.FixtureHealth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mti.FixtureHealth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mti.FixtureHealth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.FixtureHealth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoreId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getFixtureId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 store_id = 1;
 * @return {number}
 */
proto.mti.FixtureHealth.prototype.getStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.FixtureHealth} returns this
 */
proto.mti.FixtureHealth.prototype.setStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 fixture_id = 2;
 * @return {number}
 */
proto.mti.FixtureHealth.prototype.getFixtureId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.FixtureHealth} returns this
 */
proto.mti.FixtureHealth.prototype.setFixtureId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mti.PositionHealth.prototype.toObject = function(opt_includeInstance) {
  return proto.mti.PositionHealth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mti.PositionHealth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.PositionHealth.toObject = function(includeInstance, msg) {
  var f, obj = {
    storeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fixtureId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    positionId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mti.PositionHealth}
 */
proto.mti.PositionHealth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mti.PositionHealth;
  return proto.mti.PositionHealth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mti.PositionHealth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mti.PositionHealth}
 */
proto.mti.PositionHealth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStoreId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFixtureId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPositionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mti.PositionHealth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mti.PositionHealth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mti.PositionHealth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.PositionHealth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoreId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getFixtureId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPositionId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional uint64 store_id = 1;
 * @return {number}
 */
proto.mti.PositionHealth.prototype.getStoreId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.PositionHealth} returns this
 */
proto.mti.PositionHealth.prototype.setStoreId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 fixture_id = 2;
 * @return {number}
 */
proto.mti.PositionHealth.prototype.getFixtureId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.PositionHealth} returns this
 */
proto.mti.PositionHealth.prototype.setFixtureId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 position_id = 3;
 * @return {number}
 */
proto.mti.PositionHealth.prototype.getPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mti.PositionHealth} returns this
 */
proto.mti.PositionHealth.prototype.setPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mti.HealthState.oneofGroups_ = [[4,5,6]];

/**
 * @enum {number}
 */
proto.mti.HealthState.RuleOneofCase = {
  RULE_ONEOF_NOT_SET: 0,
  STORE_HEALTH: 4,
  FIXTURE_HEALTH: 5,
  POSITION_HEALTH: 6
};

/**
 * @return {proto.mti.HealthState.RuleOneofCase}
 */
proto.mti.HealthState.prototype.getRuleOneofCase = function() {
  return /** @type {proto.mti.HealthState.RuleOneofCase} */(jspb.Message.computeOneofCase(this, proto.mti.HealthState.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mti.HealthState.prototype.toObject = function(opt_includeInstance) {
  return proto.mti.HealthState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mti.HealthState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.HealthState.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadata: (f = msg.getMetadata()) && mti_metadata_pb.Metadata.toObject(includeInstance, f),
    statusUuid: msg.getStatusUuid_asB64(),
    statusUpdatedAt: (f = msg.getStatusUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    storeHealth: (f = msg.getStoreHealth()) && proto.mti.StoreHealth.toObject(includeInstance, f),
    fixtureHealth: (f = msg.getFixtureHealth()) && proto.mti.FixtureHealth.toObject(includeInstance, f),
    positionHealth: (f = msg.getPositionHealth()) && proto.mti.PositionHealth.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    reason: jspb.Message.getFieldWithDefault(msg, 8, ""),
    humanReadableReason: jspb.Message.getFieldWithDefault(msg, 9, ""),
    humanReadableReasonAction: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mti.HealthState}
 */
proto.mti.HealthState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mti.HealthState;
  return proto.mti.HealthState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mti.HealthState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mti.HealthState}
 */
proto.mti.HealthState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mti_metadata_pb.Metadata;
      reader.readMessage(value,mti_metadata_pb.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setStatusUuid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStatusUpdatedAt(value);
      break;
    case 4:
      var value = new proto.mti.StoreHealth;
      reader.readMessage(value,proto.mti.StoreHealth.deserializeBinaryFromReader);
      msg.setStoreHealth(value);
      break;
    case 5:
      var value = new proto.mti.FixtureHealth;
      reader.readMessage(value,proto.mti.FixtureHealth.deserializeBinaryFromReader);
      msg.setFixtureHealth(value);
      break;
    case 6:
      var value = new proto.mti.PositionHealth;
      reader.readMessage(value,proto.mti.PositionHealth.deserializeBinaryFromReader);
      msg.setPositionHealth(value);
      break;
    case 7:
      var value = /** @type {!proto.mti.HealthStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHumanReadableReason(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setHumanReadableReasonAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mti.HealthState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mti.HealthState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mti.HealthState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mti.HealthState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mti_metadata_pb.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getStatusUuid_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getStatusUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStoreHealth();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mti.StoreHealth.serializeBinaryToWriter
    );
  }
  f = message.getFixtureHealth();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.mti.FixtureHealth.serializeBinaryToWriter
    );
  }
  f = message.getPositionHealth();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.mti.PositionHealth.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getHumanReadableReason();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getHumanReadableReasonAction();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional Metadata metadata = 1;
 * @return {?proto.mti.Metadata}
 */
proto.mti.HealthState.prototype.getMetadata = function() {
  return /** @type{?proto.mti.Metadata} */ (
    jspb.Message.getWrapperField(this, mti_metadata_pb.Metadata, 1));
};


/**
 * @param {?proto.mti.Metadata|undefined} value
 * @return {!proto.mti.HealthState} returns this
*/
proto.mti.HealthState.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.HealthState} returns this
 */
proto.mti.HealthState.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.HealthState.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes status_uuid = 2;
 * @return {!(string|Uint8Array)}
 */
proto.mti.HealthState.prototype.getStatusUuid = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes status_uuid = 2;
 * This is a type-conversion wrapper around `getStatusUuid()`
 * @return {string}
 */
proto.mti.HealthState.prototype.getStatusUuid_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getStatusUuid()));
};


/**
 * optional bytes status_uuid = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getStatusUuid()`
 * @return {!Uint8Array}
 */
proto.mti.HealthState.prototype.getStatusUuid_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getStatusUuid()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.mti.HealthState} returns this
 */
proto.mti.HealthState.prototype.setStatusUuid = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp status_updated_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.mti.HealthState.prototype.getStatusUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.mti.HealthState} returns this
*/
proto.mti.HealthState.prototype.setStatusUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.HealthState} returns this
 */
proto.mti.HealthState.prototype.clearStatusUpdatedAt = function() {
  return this.setStatusUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.HealthState.prototype.hasStatusUpdatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional StoreHealth store_health = 4;
 * @return {?proto.mti.StoreHealth}
 */
proto.mti.HealthState.prototype.getStoreHealth = function() {
  return /** @type{?proto.mti.StoreHealth} */ (
    jspb.Message.getWrapperField(this, proto.mti.StoreHealth, 4));
};


/**
 * @param {?proto.mti.StoreHealth|undefined} value
 * @return {!proto.mti.HealthState} returns this
*/
proto.mti.HealthState.prototype.setStoreHealth = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.mti.HealthState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.HealthState} returns this
 */
proto.mti.HealthState.prototype.clearStoreHealth = function() {
  return this.setStoreHealth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.HealthState.prototype.hasStoreHealth = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FixtureHealth fixture_health = 5;
 * @return {?proto.mti.FixtureHealth}
 */
proto.mti.HealthState.prototype.getFixtureHealth = function() {
  return /** @type{?proto.mti.FixtureHealth} */ (
    jspb.Message.getWrapperField(this, proto.mti.FixtureHealth, 5));
};


/**
 * @param {?proto.mti.FixtureHealth|undefined} value
 * @return {!proto.mti.HealthState} returns this
*/
proto.mti.HealthState.prototype.setFixtureHealth = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.mti.HealthState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.HealthState} returns this
 */
proto.mti.HealthState.prototype.clearFixtureHealth = function() {
  return this.setFixtureHealth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.HealthState.prototype.hasFixtureHealth = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PositionHealth position_health = 6;
 * @return {?proto.mti.PositionHealth}
 */
proto.mti.HealthState.prototype.getPositionHealth = function() {
  return /** @type{?proto.mti.PositionHealth} */ (
    jspb.Message.getWrapperField(this, proto.mti.PositionHealth, 6));
};


/**
 * @param {?proto.mti.PositionHealth|undefined} value
 * @return {!proto.mti.HealthState} returns this
*/
proto.mti.HealthState.prototype.setPositionHealth = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.mti.HealthState.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mti.HealthState} returns this
 */
proto.mti.HealthState.prototype.clearPositionHealth = function() {
  return this.setPositionHealth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mti.HealthState.prototype.hasPositionHealth = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional HealthStatus status = 7;
 * @return {!proto.mti.HealthStatus}
 */
proto.mti.HealthState.prototype.getStatus = function() {
  return /** @type {!proto.mti.HealthStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.mti.HealthStatus} value
 * @return {!proto.mti.HealthState} returns this
 */
proto.mti.HealthState.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string reason = 8;
 * @return {string}
 */
proto.mti.HealthState.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.HealthState} returns this
 */
proto.mti.HealthState.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string human_readable_reason = 9;
 * @return {string}
 */
proto.mti.HealthState.prototype.getHumanReadableReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.HealthState} returns this
 */
proto.mti.HealthState.prototype.setHumanReadableReason = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string human_readable_reason_action = 10;
 * @return {string}
 */
proto.mti.HealthState.prototype.getHumanReadableReasonAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.mti.HealthState} returns this
 */
proto.mti.HealthState.prototype.setHumanReadableReasonAction = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * @enum {number}
 */
proto.mti.HealthStatus = {
  HEALTHY: 0,
  UNHEALTHY: 1,
  HEALTH_WARNING: 2
};

goog.object.extend(exports, proto.mti);
