import { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useAPI, useStore } from 'global'
import { useManufacturers } from 'contexts'
import { Button, SearchableSelect } from 'components'

export default function SecuredProductField(props) {
  // Since we're already getting the device products wherever we're using this component, we just
  // pass along the data, rather than having this component re-fetch the data from the API again.
  const { securityDevice, portAddress, securedProduct, product } = props

  const { t } = useTranslation(null, { useSuspense: false })
  const api = useAPI()
  const { store, updateSecurityDevice } = useStore()
  const productField = useRef()
  const manufacturerField = useRef()
  const [isEditing, setIsEditing] = useState()
  const { manufacturers } = useManufacturers()

  if (!securityDevice) return <></>

  const unassign = () => {
    if (!securedProduct) return
    api.destroySecuredProduct(securedProduct.id).then(()=>{
      api.getSecurityDevice(securityDevice.id).then(response => {
        updateSecurityDevice(response?.securityDevices?.[0])
        setIsEditing(false)
        toast.success(t('success.portUnassign'))
      })
    }).catch(()=>toast.error(t('error.portUnassign')))
  }

  const submit = () => {
    const spData = {
      id: securedProduct?.id,
      storeId: store.id,
      port: {
        securityDeviceId: securityDevice.id,
        address: portAddress,
      },
    }

    const manu = manufacturerField.current.value
    const prod = productField.current?.value

    if (manu?.id) {
      spData.manufacturerId = manu.id
      spData.otherManufacturerName = null
    } else {
      spData.manufacturerId = null
      spData.otherManufacturerName = manufacturerField.current?.text.trim()
    }

    if (prod?.id) {
      spData.productId = prod.id
      spData.otherProductName = null
    } else {
      spData.productId = null
      spData.otherProductName = productField.current?.text.trim()
    }

    // If there are no values, then treat it as an unassignment instead
    if (!(spData.productId || spData.manufacturerId || spData.otherProductName || spData.otherManufacturerName))
      return unassign()

    api.saveSecuredProduct(spData).then(() => {
      api.getSecurityDevice(securityDevice.id).then(response => {
        updateSecurityDevice(response?.securityDevices?.[0])
        setIsEditing(false)
        toast.success(t('success.portEdit'))
      })
    }).catch(() => toast.error(t('error.portEdit')))
  }

  const fetchProducts = (text, page) => {
    const mID = manufacturerField.current?.value?.id
    if (!mID) productField.current?.addData([])
    else api.getProducts(mID, text, page).then(response => {
      productField.current?.addData(response.products, response.meta)
    }).catch(() => productField.current?.addData([]))
  }

  // Whenever a different manufacturer is selected, make sure to clear the product
  const onManufacturerChange = () => { productField.current.value = null }

  const manufacturer = manufacturers?.[securedProduct?.manufacturerId]

  return (
    <div className={`secured-product-field-comp ${!isEditing && 'cursor'}`} onClick={()=>setIsEditing(true)}>
      {isEditing ? (
        <>
          <SearchableSelect
            required
            ref={manufacturerField}
            label={t('ports.manufacturer')}
            defaultValue={manufacturer}
            defaultText={securedProduct?.otherManufacturerName}
            onValueChange={v=>onManufacturerChange(v)}
            staticData={Object.values(manufacturers)}
          />
          <SearchableSelect
            required
            ref={productField}
            label={t('ports.name')}
            defaultValue={product}
            defaultText={securedProduct?.otherProductName}
            onSearch={fetchProducts}
          />
          <div className='col'>
            <div className='button-row row'>
              <Button outline onClick={()=>setIsEditing(false)}>{t('form.cancel')}</Button>
              <Button onClick={submit}>{t('form.save')}</Button>
            </div>
            <Button onClick={unassign}>{t('global.unassign')}</Button>
          </div>
        </>
      ) : (
        <>
          <p>{product?.name || securedProduct?.otherProductName || (securedProduct ? t('securedProduct.unknownProduct') : t('securedProduct.unassigned'))}</p>
          <p className='subtitle'>{manufacturer?.name || securedProduct?.otherManufacturerName}</p>
        </>
      )}
    </div>
  )
}
