import { useState, useEffect, createContext, useContext, useRef } from 'react'
import { useMobileAPI } from 'global'

const Context = createContext()
const listenerID = 'BLEProvider'

export function BLEProvider(props) {
  const mobile = useMobileAPI()
  const [isBLEEnabled, setIsBLEEnabled] = useState(false)
  const [isPermissionGranted, setIsPermissionGranted] = useState(false)
  const [isDeviceConnected, setIsDeviceConnected] = useState(false)
  const [deviceSerialNumber, setDeviceSerialNumber] = useState()
  const listeners = useRef({})

  useEffect(() => {
    mobile.listen(listenerID, (event, payload) => {
      console.log('BLEProvider received event', event, payload)
      switch (event) {
      case 'bleEnabled': setIsBLEEnabled(payload); break
      case 'bleDeviceConnected':
        setIsDeviceConnected(payload)
        if (!payload) setDeviceSerialNumber(null) // clear the serial number as well if we disconnect
        break
      case 'bleDeviceSerialNumber': setDeviceSerialNumber(payload); break
      case 'blePermissionGranted': setIsPermissionGranted(payload); break
      case 'bleDeviceUnlockResponse':
        listeners.current['bleDeviceUnlockResponse']?.forEach(listener => listener(payload))
        break
      case 'bleDeviceRebooting':
        listeners.current['bleDeviceRebooting']?.forEach(listener => listener(payload))
        break
      }


    })
    return () => mobile.unlisten(listenerID)
  }, [])

  const initBLE = () => {
    // Delay initializing the iOS central manager until now to avoid a popup on app-start. This is required to check for Bluetooth.
    mobile.post('initBLE')

    // Now, check if Bluetooth is enabled.
    mobile.post('checkBLEEnabled')

    // iOS does not require location permissions in order to use CoreBluetooth features, so we can just set them to 'granted'.
    if (mobile.isAndroid) mobile.post('checkBLEPermission')
    else setIsPermissionGranted(true)
  }

  const listen = (event, callback) => {
    if (!listeners.current[event]) listeners.current[event] = new Set()
    listeners.current[event].add(callback)
  }

  const unlisten = (event, callback) => {
    if (!listeners.current[event]?.has(callback)) return
    listeners.current[event].delete(callback)
  }

  const value = {
    isBLEEnabled,
    isPermissionGranted,
    isDeviceConnected,
    deviceSerialNumber,
    initBLE,
    listen,
    unlisten,
    requestPermission: () => mobile.post('requestBLEPermission'),
    openBLESettings: () => mobile.post('openBLESettings'),
    initScanner: () => mobile.post('initScanner'),
    startScan: () => mobile.post('startScan'),
    stopScan: () => mobile.post('stopScan'),
    unlock: keySerial => mobile.post('unlock', keySerial),
    provision: () => mobile.post('provision'),
    disconnect: () => mobile.post('disconnectFromDevice'),
  }

  return (
    <Context.Provider value={value}>
      {props.children}
    </Context.Provider>
  )
}

export function useBLE() {
  return useContext(Context)
}
