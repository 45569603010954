import { useTranslation } from 'react-i18next'
import { CableType } from 'protoModels/mti/store/cable/state_pb'
import { DeviceType as DT } from 'enums'
import { SecuredProductField } from 'components'
import './styles.scss'

export default function PortCell(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const {
    securityDevice,
    port,
    securedProduct,
    product,
  } = props

  const isCXFlex = [DT.cxFlex, DT.cxFlex1, DT.cxFlex4].includes(securityDevice?.deviceType)
  const portStatus = isCXFlex ? (
    <div className='row port-address'>
      <p className='subtitle'>{t('ports.portAddress', { address: port.address })}</p>
      <div className={`indicator ${getPortStyle(port, securityDevice)}`}></div>
    </div>
  ) : null

  return (
    <div className='port-cell-comp'>
      {portStatus}
      <SecuredProductField
        securityDevice={securityDevice}
        securedProduct={securedProduct}
        portAddress={port.address}
        product={product}
      />
    </div>
  )
}

const getPortStyle = (port, securityDevice) => {
  const unhealthyPorts = securityDevice.unhealthyDetail?.match(/\d+/g)?.map(Number)

  if (port.cableType == CableType.NOCABLECONNECTED) return 'unused'
  if (unhealthyPorts?.includes(port.address)) return 'unhealthy'
  return 'healthy'
}
