import { useTranslation } from 'react-i18next'
import { DeviceType as DT } from 'enums'
import { camelize, toFriendlyDate, toFriendlyTime } from 'utils'

import './styles.scss'

export default function SecurityDeviceInfo(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { securityDevice: device } = props

  if (!device) return <></>

  return (
    <div className='security-device-info-comp'>
      <div className='battery-device-container row'>
        <p className='device-type'>{t(`device.type.${camelize(device.deviceType)}`)}</p>
        {device?.deviceType === DT.lock &&
         <div className='row'>
           <i className={`material-icons ${getBatteryStyle(device.batteryPercentage)}`}>{getBatteryIcon(device.batteryPercentage)}</i>
           <p>{device.batteryPercentage > 0 ? `${device.batteryPercentage}%` : 'Unavailable'}</p>
         </div>
        }
      </div>

      <p className='subtitle'>{device.serialNumber}</p>

      {device?.disarmedBy && device?.disarmedAt && (
        <p className='subtitle'>
          {t('device.disarmedAt', {
            user: device.disarmedBy,
            date: toFriendlyDate(device.disarmedAt),
            time: toFriendlyTime(device.disarmedAt),
          })}
        </p>
      )}
      {device?.securityStatusUpdatedAt && (
        <p className='subtitle'>
          {t('device.securityStatusUpdatedAt', {
            date: toFriendlyDate(device.securityStatusUpdatedAt),
            time: toFriendlyTime(device.securityStatusUpdatedAt),
          })}
        </p>
      )}
    </div>
  )
}

function getBatteryIcon(percentage) {
  if (percentage <= 5) return 'battery_0_bar'
  if (percentage <= 20) return 'battery_1_bar'
  if (percentage <= 35) return 'battery_2_bar'
  if (percentage <= 50) return 'battery_3_bar'
  if (percentage <= 65) return 'battery_4_bar'
  if (percentage <= 80) return 'battery_5_bar'
  if (percentage <= 94) return 'battery_6_bar'
  return 'battery_full'
}

function getBatteryStyle(percentage) {
  if (percentage > 0 && percentage <= 20) return 'critical'
  if (percentage > 20 && percentage <= 35) return 'low'
  return 'high'
}
