// Takes in a timestamp, string, or date, and returns a formatted date.
export const toFriendlyDate = original => {
  const date = new Date(original)
  return date.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })
}

// Takes in a timestamp, string, or date, and returns a formatted time.
export const toFriendlyTime = original => {
  const date = new Date(original)
  return date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })
}
