import { useTranslation } from 'react-i18next'
import { useBLE } from 'utils'
import { Button, LoadingSpinner } from 'components'
import addLock from 'assets/images/addLock.svg'
import lockAdded from 'assets/images/lockAdded.svg'
import './styles.scss'

export const State = {
  blank: 'blank',
  loading: 'loading',
  success: 'success',
  failure: 'failure',
}

export default function BLEProvisionScreen(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const {
    deviceSerialNumber: serialNumber,
    isDeviceConnected: connected,
  } = useBLE()
  const {
    position,
    device,
    onProvision,
    onDisconnect,
    state,
  } = props

  let connectionStatus = <p className='connection-status'>{t(connected ? 'ble.connected' : 'ble.scanning')}</p>
  if (connected && serialNumber) connectionStatus = (
    <div className='main-content col'>
      <h2>{position?.name || t('ble.unassignedLock')}</h2>
      <p className='subtitle'>{serialNumber}</p>
      {(state == State.blank) && <p className='info'>{device ? t('ble.provision.provisionedLockFound') : t('ble.provision.newLockFound')}</p>}
    </div>
  )

  return (
    <div className='ble-provision-comp col'>
      <img src={state == State.success ? lockAdded : addLock} />
      <div className='content-container col'>
        {connectionStatus}
        {state == State.loading && <LoadingSpinner />}
        {state == State.success && <p>{t('ble.provision.success')}</p>}
        {state == State.failure && <p>{t('ble.provision.failure')}</p>}
      </div>
      {connected && (state == State.blank || state == State.failure) &&
        <div className='button-row row'>
          {serialNumber && <Button onClick={onProvision}>{t('ble.provision.provision')}</Button>}
          <Button outline onClick={onDisconnect}>{t('ble.disconnect')}</Button>
        </div>
      }
    </div>
  )
}
