import { useRef, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAPI, history } from 'global'
import { TextField, Button } from 'components'
import { toast } from 'react-toastify'
import './styles.scss'

export default function PasswordReset() {
  const api = useAPI()
  const { t } = useTranslation(null, { useSuspense: false })
  const [formSubmitted, setFormSubmitted] = useState(false)
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const passcodeRef = useRef()
  const passcodeConfirmRef = useRef()

  const { token, tokenKey } = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const loginToken = urlParams.get('login[reset_password_token]')
    const userToken = urlParams.get('user[password_reset_token]')
    if (loginToken) return { token: loginToken, tokenKey: 'reset_password_token' }
    else if (userToken) return { token: userToken, tokenKey: 'password_reset_token' }
  }, [])

  const submit = e => {
    e.preventDefault()

    const password = passwordRef?.current.value.trim()
    const passwordConfirm = passwordConfirmRef?.current.value.trim()
    const passcode = passcodeRef?.current.value.trim()
    const passcodeConfirm = passcodeConfirmRef?.current.value.trim()

    if (!validate(t, password, passwordConfirm, passcode, passcodeConfirm)) return

    const userData = {
      password,
      passcode,
    }

    if (token && tokenKey) userData[tokenKey] = token

    Object.keys(userData).forEach(k => {
      if (!userData[k]) delete userData[k]
    })

    api.resetPassword(userData).then(() => {
      userData.password && toast.success(t('success.password'))
      userData.passcode && toast.success(t('success.passcode'))
      setFormSubmitted(true)
    }).catch(()=>{
      toast.error(t('error.invalidPassword'))
    })
  }

  return (
    <div className='reset-password-comp page col'>
      {!formSubmitted ? (
        <div className='card col'>
          <h1>{t('forgotPassword.resetPassword')}</h1>
          <p>{t('forgotPassword.instructions')}</p>
          <p>{t('forgotPassword.passwordInstructions')}</p>
          <p>{t('forgotPassword.passcodeInstructions')}</p>
          <form onSubmit={submit} className='col'>
            <TextField
              ref={passwordRef}
              type='password'
              minLength='8'
              label={t('profile.password')}
            />
            <TextField
              ref={passwordConfirmRef}
              type='password'
              minLength='8'
              label={t('profile.passwordConfirm')}
            />
            <TextField
              ref={passcodeRef}
              type='password'
              pattern='[0-9]+'
              minLength='4'
              maxLength='4'
              label={t('profile.passcode')}
            />
            <TextField
              ref={passcodeConfirmRef}
              type='password'
              pattern='[0-9]+'
              minLength='4'
              maxLength='4'
              label={t('profile.passcodeConfirm')}
            />
            <Button type='submit'>{t('form.submit')}</Button>
          </form>
        </div>
      ) : (
        <div className='card col'>
          <p>{t('forgotPassword.success')}</p>
          <Button onClick={()=>history.push('/')}>{t('forgotPassword.returnHome')}</Button>
        </div>
      )}
    </div>
  )
}

const validate = (t, password, confirmPassword, passcode, confirmPasscode) => {
  const errors = []

  if (!password && !confirmPassword && !passcode && !confirmPasscode)
    errors.push(t('error.fieldsEmpty'))

  if (password) {
    if (password != confirmPassword)
      errors.push(t('error.passwordsMustMatch'))
  }

  if (passcode) {
    if (passcode != confirmPasscode)
      errors.push(t('error.passcodesMustMatch'))

    for (let index in passcode) {
      const idx = Number(index)
      if (passcode[idx] === passcode[idx+1]) {
        errors.push(t('error.noRepeatingNumbers'))
        break
      }
    }
  }

  const valid = !(errors.length > 0)
  if (!valid) toast.error(errors.join(' '))
  return valid
}