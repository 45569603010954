import { useState, useEffect, useRef } from 'react'
import { useVirtualKey, useStore } from 'global'
import { useBLE } from 'utils'
import Screen, { State } from './screen'

export default function BLEUnlock() {
  const {
    deviceSerialNumber: serialNumber,
    unlock,
    disconnect,
    listen,
    unlisten,
  } = useBLE()
  const { virtualKey } = useVirtualKey()
  const { securityDevicesBySerial, positionsBySecurityDevice } = useStore()
  const device = securityDevicesBySerial?.[serialNumber]
  const position = positionsBySecurityDevice?.[device?.id]
  const [state, setState] = useState(State.locked)
  const loadingTimeout = useRef()

  useEffect(() => {
    let unlockTimeout
    const handleUnlockResponse = unlocked => {
      setState(unlocked ? State.unlocked : State.locked)
      unlockTimeout = setTimeout(() => setState(State.locked), 5000)
    }

    let rebootTimeout
    const handleReboot = () => {
      setState(State.rebooting)
      rebootTimeout = setTimeout(() => setState(State.locked), 3000)
    }

    listen('bleDeviceUnlockResponse', handleUnlockResponse)
    listen('bleDeviceRebooting', handleReboot)
    return () => {
      unlisten('bleDeviceUnlockResponse', handleUnlockResponse)
      unlisten('bleDeviceRebooting', handleReboot)

      if (unlockTimeout) clearTimeout(unlockTimeout)
      if (rebootTimeout) clearTimeout(rebootTimeout)
    }
  }, [])

  useEffect(() => {
    clearTimeout(loadingTimeout.current)
    // If the loading state persists (e.g. if we don't receive a notification), then set it back to locked after a timeout
    if (state === State.loading) loadingTimeout.current = setTimeout(() => setState(State.locked), 3000)
  }, [state])

  const sendUnlock = () => {
    if (!virtualKey) return
    setState(State.loading)
    unlock(virtualKey.serialNumber)
  }

  return (
    <Screen
      position={position}
      onUnlock={sendUnlock}
      onDisconnect={disconnect}
      state={state}
    />
  )
}
