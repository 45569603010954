import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useManufacturers, useStore } from 'contexts'
import { PageHeader, ListView, SecurityDeviceInfo, openModal } from 'components'
import { toFriendlyDate, toFriendlyTime } from 'utils'
import './styles.scss'

export default function SecuredProductsScreen(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { securedProducts, products, pagination } = props
  const { manufacturers } = useManufacturers()

  const securedProductsData = useMemo(() => (securedProducts || []).map(sp => ({
    ...sp,
    manufacturer: manufacturers?.[sp.manufacturerId],
    product: products?.[sp.productId],
  })),[securedProducts])

  return (
    <div className='secured-products-comp page'>
      <PageHeader title={t('resource.securedProduct', { count: 100 })} />
      <ListView
        data={securedProductsData}
        cell={SecuredProductCell}
        onQuery={() => {}}
        onSelect={() => {}}
        meta={pagination}
      />
    </div>
  )
}

function SecuredProductCell(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { data: securedProduct } = props
  const { position, productName, manufacturerName } = useSecuredProductInfo(securedProduct)
  const open = () => openModal(<SecuredProductModal securedProduct={securedProduct} />)
  return (
    <div className='secured-product-cell-comp' onClick={open}>
      <p>{position?.name || t('global.unassigned')}</p>
      <p className='subtitle'>{productName}</p>
      <p className='subtitle'>{manufacturerName}</p>
    </div>
  )
}

function SecuredProductModal(props) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { securedProduct: sp } = props
  const {
    position,
    productName,
    manufacturerName,
    securityDevice,
    associatedSecurityDevice,
  } = useSecuredProductInfo(sp)
  return (
    <div className='secured-product-modal-comp col'>
      <h2>{position?.name || t('global.unassigned')}</h2>
      <div className='col'>
        <h3>{t('resource.securedProduct')}</h3>
        <div>
          <p className='subtitle'>{t('global.createdAt', {date: toFriendlyDate(sp.createdAt), time: toFriendlyTime(sp.createdAt)})}</p>
          {sp.lastSeenAt && <p className='subtitle'>{t('global.lastSeenAt', {date: toFriendlyDate(sp.lastSeenAt), time: toFriendlyTime(sp.lastSeenAt)})}</p>}
        </div>

        {manufacturerName && (
          <div>
            <label className='subtitle'>{t('resource.manufacturer')}</label>
            <p>{manufacturerName}</p>
          </div>
        )}

        <div>
          <label className='subtitle'>{t('resource.product')}</label>
          <p>{productName}</p>
          <p className='subtitle'>{sp.product?.model || '--'}</p>
        </div>
      </div>

      <div className='col'>
        <h3>{t('resource.securityDevice')}</h3>
        <SecurityDeviceInfo securityDevice={securityDevice} />
        <SecurityDeviceInfo securityDevice={associatedSecurityDevice} />
      </div>
    </div>
  )
}

function useSecuredProductInfo(securedProduct) {
  const { t } = useTranslation(null, { useSuspense: false })
  const { positionsBySecurityDevice, securityDevices, securityDevicesByParent } = useStore()
  const sd1 = securityDevices[securedProduct.port?.securityDeviceId]
  const sd2 = securityDevices[sd1?.parentId] || securityDevicesByParent[sd1?.id]
  return {
    position: positionsBySecurityDevice[sd1?.id] || positionsBySecurityDevice[sd2?.id],
    productName: securedProduct.product?.name || securedProduct.otherProductName || t('securedProduct.unknownProduct'),
    manufacturerName: securedProduct.manufacturer?.name || securedProduct.otherManufacturerName,
    securityDevice: sd1,
    associatedSecurityDevice: sd2,
  }
}
